/* global PublicPortalsAnalyticsFactory */

/**
  ***************************************
   FUNCIONES DE LOS EVENTOS DE ANALÍTICA
  ***************************************
*/

/**
 * Evento de visualización de un modal en la página
 */
function analyticsModalView() {

	const dataUrl = this.getAttribute("data-analytics-url");

    PublicPortalsAnalyticsFactory
        .getInstance()
        .setUrlVirtual(dataUrl ? `${dataUrl}/${this.getAttribute("data-analytics-modal-id")}` : `${PublicPortalsAnalyticsFactory.getInstance().url_virtual}${this.getAttribute("data-analytics-modal-id")}`)
        .setPageTitle(`${PublicPortalsAnalyticsFactory.getInstance().page_title} - Modal`)
        .publicPortalsAnalyticsModalViewInstance()
        .customProperties(this.getAttribute("data-custom-properties"))
        .push();
}

/**
 * Evento de interacción de las opciones del menú del header
 */
function analyticsInteraccionHeader() {
    PublicPortalsAnalyticsFactory
        .getInstance()
        .publicPortalsAnalyticsInteractionInstance()
        .accion_evento("click menu")
        .elemento_interaccion(this.getAttribute("data-elemento-interaccion"))
        .categoria_interaccion("navegacion")
        .detalle_interaccion("clic_en_menu")
        .customProperties(this.getAttribute("data-custom-properties"))
        .push();
}

/**
 * Evento de interacción de los enlaces del footer
 */
function analyticsInteraccionFooter() {
    PublicPortalsAnalyticsFactory
        .getInstance()
        .publicPortalsAnalyticsInteractionInstance()
        .accion_evento("click footer")
        .elemento_interaccion(`${this.innerText}`)
        .categoria_interaccion("navegacion")
        .detalle_interaccion("clic_en_enlace_footer")
        .customProperties(this.getAttribute("data-custom-properties"))
        .push();
}

/**
 * Evento de interacción de los botones
 */
function analyticsInteraccionBoton() {
    PublicPortalsAnalyticsFactory
        .getInstance()
        .publicPortalsAnalyticsInteractionInstance()
        .accion_evento("click boton")
        .elemento_interaccion(`${this.innerText}`)
        .customProperties(this.getAttribute("data-custom-properties"))
        .push();
}

/**
 * Evento de interacción del envío del formulario
 */
function analyticsInteraccionFormulario() {
    PublicPortalsAnalyticsFactory
        .getInstance()
        .publicPortalsAnalyticsInteractionInstance()
        .accion_evento("envio formulario")
        .elemento_interaccion(`${this.innerText}`)
        .categoria_interaccion("registro")
        .detalle_interaccion("envio_formulario")
        .customProperties(this.getAttribute("data-custom-properties"))
        .push();
}

/**
 * Evento de error de un campo al enviar un formulario
 */
function analyticsErrorFormulario() {
    PublicPortalsAnalyticsFactory
        .getInstance()
        .publicPortalsAnalyticsNavErrorInstance()
        .elemento_interaccion(this.getAttribute("data-elemento-interaccion"))
        .categoria_interaccion("registro")
        .detalle_interaccion("envio_formulario")
        .customProperties(this.getAttribute("data-custom-properties"))
        .push();
}

/**
 * Evento de interacción de las tarjetas de los seguros
 */
function analyticsInteraccionTarjeta() {
    PublicPortalsAnalyticsFactory
        .getInstance()
        .publicPortalsAnalyticsInteractionInstance()
        .accion_evento("tarjeta seguro")
        .elemento_interaccion(this.getAttribute("data-elemento-interaccion"))
        .categoria_interaccion("contenido")
        .detalle_interaccion("clic_en_tarjeta")
        .customProperties(this.getAttribute("data-custom-properties"))
        .push();
}

/**
 * Evento de interacción del botón "¿Necesitas ayuda?"
 */
function analyticsInteraccionAyuda() {
    PublicPortalsAnalyticsFactory
        .getInstance()
        .publicPortalsAnalyticsInteractionInstance()
        .accion_evento("contacto")
        .elemento_interaccion(`${this.innerText}`)
        .categoria_interaccion("soporte")
        .detalle_interaccion("clic_en_ayuda")
        .customProperties(this.getAttribute("data-custom-properties"))
        .push();
}

/**
 * Evento de interacción al plegar/desplegar una sección de las preguntas frecuentes
 */
function analyticsInteraccionSeccionFAQ() {
    // Obtenemos el elemento que tenga el atributo "data-open"
    const respuestaSeccionFAQ = this.nextElementSibling;
    let dataOpenAttribute;

    if (respuestaSeccionFAQ) {
         dataOpenAttribute = respuestaSeccionFAQ.getAttribute("data-open");
    }

    PublicPortalsAnalyticsFactory
        .getInstance()
        .publicPortalsAnalyticsInteractionInstance()
        .accion_evento(`${dataOpenAttribute === "true" ? "abrir" : "cerrar"} contenido`)
        .elemento_interaccion(`${this.innerText}`)
        .categoria_interaccion("soporte")
        .detalle_interaccion("expandir_pregunta")
        .customProperties(this.getAttribute("data-custom-properties"))
        .push();
}

/**
 * Evento que se envía al realizar una búsqueda
 */
function analyticsSearch() {
	try {
		const searchElement = this.getAttribute("data-custom-search-term");

		const elements = searchElement.split(",");

		let result = "";

		elements.forEach(element => {
		    try {
		        let searchValue;

                if (element.trim() === "textTipoTaller") {
                    searchValue = document.getElementById(element.trim()).innerText;
                } else if (
                    element.trim().includes("checkpDigital") ||
                    element.trim().includes("checkvhSustitution") ||
                    element.trim().includes("checkfTrack") ||
                    element.trim().includes("checkaDirecta")
                ) {
					let search = document.getElementById(element.trim());

                	if (search && search.checked) {
						let etiqueta = search.nextElementSibling.textContent;
						searchValue = etiqueta;
                    }
                } else {
                    searchValue = document.getElementById(element.trim()).value;
                }

                if (result.length !== 0) {
                	if (searchValue.trim().length !== 0) {
						result += ", " + searchValue;
					}
                } else {
                    result += searchValue
                }
            } catch(error) {
            }
		});

		PublicPortalsAnalyticsFactory
			.getInstance()
			.publicPortalsAnalyticsSearchInstance()
			.categoria_interaccion("busqueda")
			.detalle_interaccion("iniciar_busqueda")
            .elemento_interaccion(this.getAttribute("data-elemento-interaccion"))
			.search_term(result)
            .content_type(this.getAttribute("data-content-type"))
            .content_id(this.getAttribute("data-medor-name"))
			.customProperties(this.getAttribute("data-custom-properties"))
			.push();
	} catch {}
}

/**
 * Evento que se envía al visualizar los resultados de una búsqueda
 */
function analyticsViewContentSearch() {
    const searchTerm = Array.from(document.querySelectorAll("[data-search-term]"))
        .map(i => i.getAttribute("data-search-term"))
        .join(" - ");

    PublicPortalsAnalyticsFactory
        .getInstance()
        .publicPortalsAnalyticsViewContentInstance()
        .accion_evento("resultado busqueda")
        .elemento_interaccion()
        .categoria_interaccion("busqueda")
        .detalle_interaccion("impresion_resultados")
        .search_term(searchTerm)
        .content_type(this.getAttribute("data-content-type"))
        .content_id()
        .customProperties(this.getAttribute("data-custom-properties"))
        .push();
}

/**
 * Evento al interactuar con un enlace para ver detalles de un resultado de búsqueda
 */
function analyticsSelectContentSearch() {
    try {
        const searchElement = this.getAttribute("data-custom-search-term");

        const elements = searchElement.split(",");

        let result = "";

        elements.forEach(element => {
            try {
                let searchValue;

                if (element.trim() === "textTipoTaller") {
                    searchValue = document.getElementById(element.trim()).innerText;
                } else if (
                    element.trim().includes("checkpDigital") ||
                    element.trim().includes("checkvhSustitution") ||
                    element.trim().includes("checkfTrack") ||
                    element.trim().includes("checkaDirecta")
                ) {
                    let search = document.getElementById(element.trim());

                    if (search && search.checked) {
                        let etiqueta = search.nextElementSibling.textContent;
                        searchValue = etiqueta;
                    }
                } else {
                    searchValue = document.getElementById(element.trim()).value;
                }

                if (result.length !== 0) {
                    if (searchValue.trim().length !== 0) {
                        result += ", " + searchValue;
                    }
                } else {
                    result += searchValue
                }
            } catch(error) {
            }
        });

        PublicPortalsAnalyticsFactory
            .getInstance()
            .publicPortalsAnalyticsSelectContentInstance()
            .accion_evento("resultado busqueda")
            .elemento_interaccion(this.getAttribute("data-elemento-interaccion"))
            .categoria_interaccion("busqueda")
            .detalle_interaccion("clic_en_resultado")
            .search_term(result)
            .content_type(this.getAttribute("data-content-type"))
            .content_id(this.getAttribute("data-medor-name"))
            .customProperties(this.getAttribute("data-custom-properties"))
            .push();
    } catch {}
}

/**
 * Evento de interacción de un enlace a un teléfono
 */
function analyticsInteraccionContacto() {
    // Si el teléfono está en una sección o tarjeta, se añade el título, si no solo se envía el teléfono
    const elementoInteraccion = this.getAttribute("data-elemento-interaccion") || this.innerText;

    PublicPortalsAnalyticsFactory
        .getInstance()
        .publicPortalsAnalyticsInteractionInstance()
        .accion_evento("contacto")
        .elemento_interaccion(elementoInteraccion)
        .categoria_interaccion("contacto")
        .detalle_interaccion("enviar_mensaje")
        .customProperties(this.getAttribute("data-custom-properties"))
        .push();
}

/**
 * Evento al interactuar con el enlace de una tarjeta para acceder a un blog
 */
function analyticsInteraccionTarjetaBlog() {
    PublicPortalsAnalyticsFactory
        .getInstance()
        .publicPortalsAnalyticsInteractionInstance()
        .accion_evento("click tarjeta blog")
        .elemento_interaccion(this.getAttribute("data-elemento-interaccion"))
        .categoria_interaccion("contenido")
        .detalle_interaccion("clic_en_blog")
        .customProperties(this.getAttribute("data-custom-properties"))
        .push();
}

/**
 * Evento al visualizar las tarjetas de los artículos del blog
 */
function analyticsViewContentArticulos(element) {
    PublicPortalsAnalyticsFactory
        .getInstance()
        .publicPortalsAnalyticsViewContentInstance()
        .accion_evento("articulo blog")
        .elemento_interaccion(element.getAttribute("data-elemento-interaccion"))
        .categoria_interaccion("contenido")
        .detalle_interaccion("impresion_articulo")
        .content_type(element.getAttribute("data-content-type"))
        .content_id(element.getAttribute("data-content-id"))
        .customProperties(element.getAttribute("data-custom-properties"))
        .push();
}

/**
 * Evento al interactuar con un enlace del detalle de un artículo del blog
 */
function analyticsSelectContentArticulo() {
    PublicPortalsAnalyticsFactory
        .getInstance()
        .publicPortalsAnalyticsSelectContentInstance()
        .accion_evento("articulo blog")
        .elemento_interaccion(this.getAttribute("data-elemento-interaccion"))
        .categoria_interaccion("contenido")
        .detalle_interaccion("clic_en_articulo")
        .content_type(this.getAttribute("data-content-type"))
        .content_id(this.getAttribute("data-content-id"))
        .customProperties(this.getAttribute("data-custom-properties"))
        .push();
}

/**
 * Evento al interactuar con uno de los elementos de la pantalla de recuperación de presupuesto
 */
function analyticsInteraccionRecuperarPresupuesto() {
    PublicPortalsAnalyticsFactory
        .getInstance()
        .publicPortalsAnalyticsInteractionInstance()
        .accion_evento("recuperar presupuesto")
        .elemento_interaccion(this.getAttribute("data-elemento-interaccion"))
        .categoria_interaccion("cta")
        .detalle_interaccion("clic_en_boton")
        .customProperties(this.getAttribute("data-custom-properties"))
        .push();
}

/**
 * Función encargada de enlazar todas las funciones al evento 'click' de los elementos con las clases de la analítica
 */
function analyticFunctionsActivate() {
    document.querySelectorAll(".analytics_interaccion_header")
        .forEach(element => element.addEventListener("click", analyticsInteraccionHeader));

    document.querySelectorAll(".analytics_interaccion_footer")
        .forEach(element => element.addEventListener("click", analyticsInteraccionFooter));

    document.querySelectorAll(".analytics_interaccion_boton")
        .forEach(element => element.addEventListener("click", analyticsInteraccionBoton));

    document.querySelectorAll(".analytics_interaccion_formulario")
        .forEach(element => element.addEventListener("click", analyticsInteraccionFormulario));

    document.querySelectorAll(".analytics_error_formulario")
        .forEach(element => element.addEventListener("click", analyticsErrorFormulario));

    document.querySelectorAll(".analytics_interaccion_tarjeta")
        .forEach(element => element.addEventListener("click", analyticsInteraccionTarjeta));

    document.querySelectorAll(".analytics_interaccion_ayuda")
        .forEach(element => element.addEventListener("click", analyticsInteraccionAyuda));

    document.querySelectorAll(".analytics_interaccion_seccion_faq")
        .forEach(element => element.addEventListener("click", analyticsInteraccionSeccionFAQ));

    document.querySelectorAll(".analytics_search")
        .forEach(element => element.addEventListener("click", analyticsSearch));

    document.querySelectorAll(".analytics_select_content_search")
        .forEach(element => element.addEventListener("click", analyticsSelectContentSearch));

    document.querySelectorAll(".analytics_interaccion_contacto")
        .forEach(element => element.addEventListener("click", analyticsInteraccionContacto));

    document.querySelectorAll(".analytics_interaccion_tarjeta_blog")
        .forEach(element => element.addEventListener("click", analyticsInteraccionTarjetaBlog));

    document.querySelectorAll(".analytics_select_content_articulo")
        .forEach(element => element.addEventListener("click", analyticsSelectContentArticulo));

    document.querySelectorAll(".analytics_interaccion_recuperar_presupuesto")
        .forEach(element => element.addEventListener("click", analyticsInteraccionRecuperarPresupuesto));

    document.querySelectorAll(".analytics_modal_view")
        .forEach(element => element.addEventListener("click", analyticsModalView));

    const seccionesFAQ = document.querySelectorAll(".analytics_interaccion_seccion_faq");

    // Añadimos el evento de "analytics_interaccion_contacto" para los teléfonos dentro de las respuestas de las FAQ's
    if (seccionesFAQ && seccionesFAQ.length) {
        seccionesFAQ.forEach(element => {
            const respuestaSeccionFAQ = element.nextElementSibling;

            if (respuestaSeccionFAQ) {
                const telefonosSeccionFAQ = respuestaSeccionFAQ.querySelectorAll('[href^="tel:"]');

                if (telefonosSeccionFAQ && telefonosSeccionFAQ.length) {
                    telefonosSeccionFAQ.forEach(tlfElement => tlfElement.addEventListener("click", analyticsInteraccionContacto));
                }
            }
        });
    }

    const articulosBlog = document.querySelectorAll(".analytics_view_content_articulos");

    // Realizamos push de "view_content" cuando hay artículos de blog en la pantalla
    if (articulosBlog && articulosBlog.length) {
        articulosBlog.forEach(element => {
            analyticsViewContentArticulos(element);
        });
    }
}

export default analyticFunctionsActivate;